import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TitleStatusQuotation from './../components/title-status-quotation';
import Hr from '../../../components/Style/hr';

export default function PaidStepAcceptQuotation({ quotation }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/quotation/services/' + quotation._id);
        }, 5000);

        return () => clearTimeout(timer);
    }, [navigate, quotation._id]);

    return (
        <div className='px-4 md:px-0'>
            <TitleStatusQuotation title={t('pageQuotation.paid.title')} description={t('pageQuotation.paid.description')} />
            <Hr />
        </div>
    );
}