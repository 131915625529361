import H2 from '../../../components/Titles/h2';
import { ReactComponent as Step1Svg } from '../../../assets/icons/quotation/step1.svg';
// import { ReactComponent as Step2Svg } from '../../../assets/icons/quotation/step2.svg';
import { ReactComponent as Step3Svg } from '../../../assets/icons/quotation/step3.svg';
import SvgImage from '../../../components/Images/svg';
import H3 from '../../../components/Titles/h3';

export default function TitleQuotation({ title, subTitle, description, step, fullWidth = false }) {
    return (
        <div className='px-4 md:px-0'>
            <H2 title={title} />

            {subTitle && <H3 title={subTitle} fontWeight='medium' />}

            <div className='flex flex-col mt-5 md:flex-row'>
                <p className={`w-full ${fullWidth ? '' : 'md:w-7/12'} text-description opacity-60`}>
                    {description}
                </p>

                {step && <div className='w-full md:w-5/12 mt-3 md:mt-0'>
                    <div className='flex justify-between md:justify-end items-center gap-3'>
                        <SvgImage
                            SvgComponent={Step1Svg}
                            alt="Step 1 Icon"
                            customHeight="28px"
                            customWidth="28px"
                            className="your-additional-classes"
                            color={Number(step) >= 1 ? "#cea95a" : "#c5c6c9"}
                        />

                        {/* <div className="w-1/4 md:w-7 h-0.5 bg-gray-200 rounded" />

                        <SvgImage
                            SvgComponent={Step2Svg}
                            alt="Step 2 Icon"
                            customHeight="28px"
                            customWidth="28px"
                            className="your-additional-classes"
                            color={Number(step) >= 2 ? "#cea95a" : "#c5c6c9"}
                        /> */}

                        <div className="w-1/4 md:w-7 h-0.5 bg-gray-200 rounded" />

                        <SvgImage
                            SvgComponent={Step3Svg}
                            alt="Step 3 Icon"
                            customHeight="28px"
                            customWidth="28px"
                            className="your-additional-classes"
                            color={Number(step) >= 3 ? "#cea95a" : "#c5c6c9"}
                        />
                    </div>
                </div>}
            </div>
        </div>
    )
};