import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import './i18n';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import QuotationPage from './containers/Quotation/pages/quotation-page';
import Reservation from './containers/Reservation/reservation';
import Layout from './containers/Layout/layout';
import Domain from './containers/Domain/pages/domain';
import HouseDetails from './containers/Domain/pages/house-details';
import Infos from './containers/Infos/infos';
import Contact from './containers/Contact/contact';
import History from './containers/History/history';
import Events from './containers/Events/events';
import Services from './containers/Services/services';
import ErrorPage from "./containers/Error/error-page";
import AnnexDetails from './containers/Domain/pages/annex-details';
import Api from './services/api.service';
import CGV from './containers/CGV/cgv';
import GardenDetails from './containers/Domain/pages/garden-details';
import ServicesQuotation from './containers/Quotation/pages/services';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout children={<App />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Layout children={<ErrorPage />} />,
  },
  {
    path: "/quotation/services",
    element: <ServicesQuotation />,
  },
  {
    path: "/quotation/services/:id",
    element: <ServicesQuotation />,
  },
  {
    path: "/quotation/:id",
    element: <QuotationPage />,
  },
  {
    path: "/reservation",
    element: <Layout children={<Reservation />} />,
  },
  {
    path: "/domain",
    element: <Layout children={<Domain />} />,
  },
  {
    path: "/gaia",
    element: <Layout children={<HouseDetails />} />,
  },
  {
    path: "/hestia",
    element: <Layout children={<AnnexDetails />} />,
  },
  {
    path: "/outside",
    element: <Layout children={<GardenDetails />} />,
  },
  {
    path: "/services",
    element: <Layout children={<Services />} />,
  },
  {
    path: "/events",
    element: <Layout children={<Events />} />
  },
  {
    path: "/history",
    element: <Layout children={<History />} />
  },
  {
    path: "/infos",
    element: <Layout children={<Infos />} />
  },
  {
    path: "/contact",
    element: <Layout children={<Contact />} />
  },
  {
    path: "/cgv",
    element: <Layout children={<CGV />} />
  }
]);

const getBlockedDates = async () => {
  try {
    const { data } = await Api.getBlockedDates();
    if (data.status) {
      const blockedDates = data.data.map(date => ({
        startDate: new Date(date.start).setHours(0, 0, 0, 0),
        endDate: new Date(date.end).setHours(0, 0, 0, 0)
      }));

      Api.storeBlockedDates(blockedDates);

    } else {
      console.log("Error:", data.error);
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

getBlockedDates();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
