import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import H2 from '../../../../components/Titles/h2';
import PaiementCard from '../../../../components/Inputs/paiement-card';

import lodash from "lodash";

export default function PaiementMethodFormQuotation({ quotation, onCardClicked }) {
    const [cardClicked, setCardClicked] = useState(null);

    const { t } = useTranslation();

    const handleChildClick = (name) => {
        setCardClicked(name);
        onCardClicked(name);
    };

    if (!lodash.get(quotation, 'prices.enableDeposit', true) && !cardClicked) {
        handleChildClick("payNow");
    }

    return (
        <div className='px-4 md:px-0'>
            <H2 title={t('pageQuotation.step3.choosePaiementMethod')} />
            <div className="grid gap-y-8 grid-cols-1 mt-10 md:grid-cols-2 md:gap-x-32">
                <PaiementCard
                    name="payNow"
                    title={t('pageQuotation.step3.payNow.title')}
                    description={t('pageQuotation.step3.payNow.shortDescription')}
                    iconKey="faCreditCard"
                    onClick={handleChildClick}
                    selected={cardClicked}
                />

                {lodash.get(quotation, 'prices.enableDeposit', true) &&
                    <PaiementCard
                        name="payLater"
                        title={t('pageQuotation.step3.payLater.title')}
                        description={t('pageQuotation.step3.payLater.shortDescription')}
                        iconKey="faClock"
                        onClick={handleChildClick}
                        selected={cardClicked}
                    />}

                {/* <PaiementCard
                    name="payByTransfer"
                    title={t('pageQuotation.step3.payByTransfer.title')}
                    description={t('pageQuotation.step3.payByTransfer.shortDescription')}
                    iconKey="faMoneyBillTransfer"
                    onClick={handleChildClick}
                    selected={cardClicked}
                />

                <PaiementCard
                    name="payByPhone"
                    title={t('pageQuotation.step3.payByPhone.title')}
                    description={t('pageQuotation.step3.payByPhone.shortDescription')}
                    iconKey="faPhone"
                    onClick={handleChildClick}
                    selected={cardClicked}
                /> */}
            </div>
        </div>
    )
};